//
// parsley.scss
//

.parsley-errors-list {
  padding: 0;
  margin: 0;

  > li {
    position: relative;
    display: inline-block;
    padding: 4px 7px 4px 28px;
    margin-top: 10px;
    color: $danger;
    list-style: none;
    background-color: rgba($danger, 0.2);
    border-radius: 7px;

    &::before {
      position: absolute;
      top: 4px;
      left: 8px;
      font-family: 'unicons';
      content: '\ebee';
    }
    &::after {
      position: absolute;
      top: -16px;
      left: 14px;
      content: '';
      border: 8px solid transparent;
      border-bottom-color: rgba($danger, 0.2);
    }
  }
}

.parsley-error {
  border-color: $danger;
}

.parsley-success {
  border-color: $success;
}
