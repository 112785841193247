.vue-notification-group {
  .vue-notification.vue-notification-template {
    padding: 0;
    background-color: #fff;
    border-left: 0;

    .notification-title {
      padding: 3px 10px;
    }

    .notification-content {
      padding: 12px 10px;
    }

    &.error {
      .notification-title {
        background-color: $danger;
        border: 1px solid $danger;
      }
      .notification-content {
        color: $danger;
        border: 1px solid $danger;
      }
    }

    &.success {
      .notification-title {
        background-color: $success;
        border: 1px solid $success;
      }
      .notification-content {
        color: $success;
        border: 1px solid $success;
      }
    }
  }
}
