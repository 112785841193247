/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Custom Bootstrap Css File
*/

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/bootstrap';

//Components
@import 'custom/components/accordions';
@import 'custom/components/alerts';
@import 'custom/components/badge';
@import 'custom/components/backgrounds';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/card';
@import 'custom/components/dropdown';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/notification';

.btn-dark-blue {
  color: #ffffff;
  background-color: #284373;
  border-color: #1E345C;
}

.btn-dark-blue:hover,
.btn-dark-blue:focus,
.btn-dark-blue:active,
.btn-dark-blue.active,
.open .dropdown-toggle.btn-dark-blue {
  color: #ffffff;
  background-color: #1E3152;
  border-color: #1E345C;
}

.btn-dark-blue:active,
.btn-dark-blue.active,
.open .dropdown-toggle.btn-dark-blue {
  background-image: none;
}

.btn-dark-blue.disabled,
.btn-dark-blue[disabled],
fieldset[disabled] .btn-dark-blue,
.btn-dark-blue.disabled:hover,
.btn-dark-blue[disabled]:hover,
fieldset[disabled] .btn-dark-blue:hover,
.btn-dark-blue.disabled:focus,
.btn-dark-blue[disabled]:focus,
fieldset[disabled] .btn-dark-blue:focus,
.btn-dark-blue.disabled:active,
.btn-dark-blue[disabled]:active,
fieldset[disabled] .btn-dark-blue:active,
.btn-dark-blue.disabled.active,
.btn-dark-blue[disabled].active,
fieldset[disabled] .btn-dark-blue.active {
  background-color: #284373;
  border-color: #1E345C;
}

.btn-dark-blue .badge {
  color: #284373;
  background-color: #ffffff;
}

.btn-landing{
  background: #fff;
  color: #1E3152;
  font-size: 18px;
  font-weight: bold;
  padding: 4px 12px;
}

.text-dark-blue{
  color: #284373;
}
.text-orange{
  color: #ec792a;
}

td.actions {
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  word-wrap: break-word;
  min-width: 140px;
  max-width: 140px;
  .btn-delete{
    padding:0 10px;
    color: chocolate;
    font-size: 22px;
    &:hover{
      opacity: .8;
    }
  }
}
.table-responsive{
  td{
    vertical-align: middle;
  }
}
.btn-action{
  background: transparent !important;
  border: 0 !important;
  font-size: 30px;
  padding: 0;
  &:hover{
    opacity: .8;
  }
  &:focus{
    box-shadow: none !important;
  }
  span[class*="spinner"]{
    vertical-align: baseline;
    width: 25px;
    height: 25px;
  }
}
