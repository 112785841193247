//
// badge.scss
//
.badge-soft {
  color: $gray-800;
}

// badge soft

@mixin badge-soft-variant($bg) {
  color: $bg !important;
  background-color: rgba($bg, 0.2);
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-soft-variant($value);
  }
}
