//
// apexcharts.scss
//
.apex-charts {
  min-height: 10px !important;

  text {
    font-family: $font-family-base !important;
    fill: $gray-700;
  }

  .apexcharts-canvas {
    margin: 0 auto;
  }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: $font-family-base !important;
}

.apexcharts-legend-series {
  font-weight: 400;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: $apex-grid-color;
}

.apexcharts-legend-text {
  font-family: $font-family-base !important;
  font-size: 13px !important;
  color: $gray-600 !important;
}

.apexcharts-pie-label {
  fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
  text {
    font-family: $font-family-base !important;
    fill: $gray-500;
  }
}

.apexcharts-point-annotations,
.apexcharts-xaxis-annotations,
.apexcharts-yaxis-annotations {
  text {
    fill: $white;
  }
}

.apexcharts-radar-series {
  polygon {
    fill: transparent;
    stroke: $gray-300;
  }

  line {
    stroke: $gray-300;
  }
}

.apexcharts-pie-label,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  fill: $white !important;
}
