//
// dropzone.scss
//

.vue-dropzone {
  cursor: pointer;
  background: $white;
  border: 2px dashed rgba($dark, 0.3) !important;
  border-radius: 6px;

  .dz-message {
    margin: 3em 0 !important;
    font-size: 1.5rem;
  }
}
