//
// nice-select.scss
//

@import '~vue-multiselect/dist/vue-multiselect.min.css';

.is-invalid {
  .multiselect__tags {
    border: $input-border-width solid #ff5c75 !important;
  }
}
.multiselect__tags {
  min-height: $input-height !important;
  padding: $input-padding-y $input-padding-x !important;
  font-family: $font-family-base !important;

  @include font-size($input-font-size);

  font-weight: $input-font-weight !important;
  line-height: $input-line-height !important;
  color: $input-color !important;
  background-color: $input-bg !important;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color !important;
}

.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: $primary !important;
}

.multiselect__placeholder {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.multiselect__input {
  margin-bottom: 0 !important;
}

.multiselect__single {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.multiselect__tag {
  margin-bottom: 0 !important;
  background-color: $primary !important;
}

.multiselect__tag-icon::after {
  color: $gray-100 !important;
}
