//
// dropdown.scss
//

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  margin: 0;
  font-size: $font-size-base;
  box-shadow: $box-shadow;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  &.show {
    top: 100% !important;
  }
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='left'] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

// Dropdown Large (Custom)
.dropdown-lg {
  width: $dropdown-lg-width;
}

@include media-breakpoint-down(sm) {
  .dropdown-lg {
    width: 200px !important;
  }
}

.drop-ani-none {
  .dropdown-menu {
    top: 0 !important;
    animation: none !important;
  }
}
