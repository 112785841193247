//
// formm-wizard.scss
//
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';

.wizard-header {
  display: none;
}

.wizard-icon-container.tab_shape,
.wizard-icon-circle.md {
  &:focus {
    outline: none !important;
  }
}

.wizard-icon-circle.checked {
  color: $primary;
}

.wizard-icon-circle {
  width: 40px !important;
  height: 40px !important;
  font-size: 14px !important;
  font-style: inherit !important;
}

.wizard-navigation .wizard-progress-with-circle {
  top: 28px !important;
}

.wizard-progress-with-circle {
  background: $gray-300;
}

.wizard-tab-content {
  padding-top: 40px !important;
}

.wizard-icon-container {
  .wizard-icon {
    font-size: 14px !important;
    font-style: inherit;
  }
}
